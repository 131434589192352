import { Typography } from '@material-ui/core';
import React from 'react';

export const MultiRegionCard = () => {
  return (
    <Typography variant="body1" align="justify">
      This plugin allows seamless switching of infrastructure between AWS regions. 
      It ensures high availability and disaster recovery by automatically redirecting 
      traffic to a healthy region in case of failure. Easily configurable via the 
      settings page.
    </Typography>
  );
};
