import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  microsoftAuthApiRef,
  SignInPageProps,
} from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
// import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ValidateGitlabGroupFieldExtension } from './scaffolder/GitlabGroupPicker';
import { FlushcachePage } from '@internal/backstage-plugin-flushcache';
import { AdminPanelPage } from '@internal/backstage-plugin-admin-panel';
import { AccountsAdminPanelPage } from '@internal/backstage-plugin-accounts-admin-panel';
import {
  adminPanelAccessPermission,
  awsAccountsBillingPermission,
  awsAccountsCloudopsPermission,
  awsAccountsSecurityPermission,
  restartpodsPermission,
} from '@internal/backstage-plugin-aws-accounts-common';
import {
  AccountsApprovalPanelPage,
  ReviewersTeams,
} from '@internal/backstage-plugin-aws-accounts-panel';
import { AwsAccountsRequestPage } from '@internal/backstage-plugin-aws-accounts-request';
import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';
import { BillingBoardsPage } from '@internal/backstage-plugin-billing-boards';
import { GroupPanelPage } from '@internal/backstage-plugin-group-panel';
import { KhatuGptPage } from '@internal/backstage-plugin-khatu-gpt';
import { OpsgeniePage } from '@internal/backstage-plugin-opsgenie';
import { PodAnnotationsPage } from '@internal/backstage-plugin-pod-annotations';
import { ReportsPage } from '@internal/backstage-plugin-reports';

import { RdsQueryRunnerPage } from '@internal/backstage-plugin-rds-query-runner';
import { TimeSaverPage } from '@internal/backstage-plugin-timesaver-internal';
import {
  CustomDarkThemeProvider,
  CustomLightThemeProvider,
} from './theme/CustomTheme';
import { KhatuGroupsPage } from '@internal/backstage-plugin-khatu-groups';
import { CloudCarbonFootprintPage } from '@internal/backstage-plugin-khatu-ccf';
import { CopilotIndexPage } from '@backstage-community/plugin-copilot';
// awana plugins import
import { AwanaToolsPage } from '@internal/backstage-plugin-awana-tools';
import {
  AwanaStoryTellerPluginPage,
  AwanaStoryTellerCardPresentation,
} from '@nx-idp/backstage-plugin-awana-story-teller-plugin';
import {
  AwanaCraftestPluginPage,
  AwanaCraftestCardPresentation,
} from '@nx-idp/backstage-plugin-awana-craftest-frontend';
import { AwanaCheckmatePluginPage } from '@nx-idp/backstage-plugin-awana-checkmate-frontend';
import { MultiRegionPage } from '@nx-idp/backstage-plugin-multi-region-frontend';
import { UpdateEKSPage } from '@nx-idp/backstage-plugin-update-eks-frontend';
import { SoftwareCatalogPage } from '@nx-idp/backstage-plugin-software-catalog-frontend';
import {
  PresentationPluginPage,
  PluginCategory,
} from '@nx-idp/backstage-plugin-khatu-x-presentation-frontend';
// import { SageMakerManagerPage } from '@nx-idp/backstage-plugin-sagemaker-manager-frontend';
// import { SageMakerPipelinesPage } from '@nx-idp/backstage-plugin-sagemaker-pipelines-frontend';
// import { SageMakerModelsPage } from '@nx-idp/backstage-plugin-sagemaker-models-frontend';
import { templateFilter } from './scaffolder/templateFilter';
import {
  GlobalFeedbackPage,
  OpcFeedbackComponent,
} from '@internal/backstage-plugin-feedback';
import { MultiRegionCard } from './components/PresentationCards/MultiRegionCard';
import { RestartPodsCard } from './components/PresentationCards/RestartPodsCard';
// import { AwanaStoryTellerCard } from './components/PresentationCards/AwanaStoryTellerCard';
// import { AwanaCraftestCard } from './components/PresentationCards/AwanaCraftestCard';
import {
  AwanaTestgeniusCardPresentation,
  AwanaTestgeniusPluginPage,
} from '@nx-idp/backstage-plugin-awana-testgenius';
import { AwanaLoadTestPluginPage } from '@nx-idp/backstage-plugin-awana-loadtest-frontend';
import { InsufficientPermissionsPage } from './components/utils/InsuffientPermission';

const KhatuSignInPage: React.FC<SignInPageProps> = ({ ...props }) => {
  return (
    <div>
      <SignInPage
        {...props}
        providers={[
          {
            id: 'microsoft-auth-provider',
            title: 'Microsoft Active Directory',
            message: 'Sign in to IDP App using your AAD account',
            apiRef: microsoftAuthApiRef,
          },
        ]}
        title="Bienvenido a Khatu X"
        align="center"
        onSignInSuccess={async identityApi => {
          const { token } = await identityApi.getCredentials();
          const { email } = await identityApi.getProfileInfo();
          if (email) {
            // For analytics purposes
            sessionStorage.setItem('p-userIdParam', btoa(email));
          }
          if (token) {
            localStorage.setItem('KhatuToken', token);
          }
          return props.onSignInSuccess(identityApi);
        }}
      />
    </div>
  );
};

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => <KhatuSignInPage {...props} />,
  },
  themes: [
    {
      id: 'my-dark-theme',
      title: 'Khatu Dark Theme',
      variant: 'dark',
      Provider: CustomDarkThemeProvider,
    },
    {
      id: 'my-light-theme',
      title: 'Khatu Light Theme',
      variant: 'light',
      Provider: CustomLightThemeProvider,
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
          templateFilter={templateFilter}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <ValidateGitlabGroupFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/flushcache" element={<FlushcachePage />} />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/reports" element={<ReportsPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route
      path="/admin-panel"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <AdminPanelPage />
        </RequirePermission>
      }
    />
    <Route
      path="/group-panel"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <GroupPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-groups" element={<KhatuGroupsPage />} />
    <Route
      path="/aws-accounts-billing-panel"
      element={
        <RequirePermission
          permission={awsAccountsBillingPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={awsAccountsBillingPermission}
            />
          }
        >
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.BILLING }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-cloudops-panel"
      element={
        <RequirePermission
          permission={awsAccountsCloudopsPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={awsAccountsCloudopsPermission}
            />
          }
        >
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.CLOUDOPS }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-security-panel"
      element={
        <RequirePermission
          permission={awsAccountsSecurityPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={awsAccountsSecurityPermission}
            />
          }
        >
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.SECURITY }} />
        </RequirePermission>
      }
    />
    <Route
      path="/accounts-admin-panel"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <AccountsAdminPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/aws-accounts-request" element={<AwsAccountsRequestPage />} />
    <Route path="/billing-boards" element={<BillingBoardsPage />} />
    <Route path="/software-catalog" element={<SoftwareCatalogPage />} />
    <Route path="/rds-query-runner" element={<RdsQueryRunnerPage />} />
    <Route
      path="/time-saver"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <TimeSaverPage />
        </RequirePermission>
      }
    />
    <Route
      path="/pod-annotations"
      element={
        <RequirePermission
          permission={restartpodsPermission}
          errorPage={
            <InsufficientPermissionsPage permission={restartpodsPermission} />
          }
        >
          <PodAnnotationsPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-gpt" element={<KhatuGptPage />} />
    <Route
      path="/cloud-carbon-footprint"
      element={<CloudCarbonFootprintPage />}
    />
    <Route path="/copilot" element={<CopilotIndexPage />} />
    <Route path="/awana-tools" element={<AwanaToolsPage />} />
    <Route
      path="/awana-story-teller"
      element={<AwanaStoryTellerPluginPage />}
    />
    {/* <Route path="/sagemaker-manager" element={<SageMakerManagerPage />}>
      <Route path="pipelines" element={<SageMakerPipelinesPage />} />
      <Route path="models" element={<SageMakerModelsPage />} />
    </Route> */}
    <Route path="/awana-testgenius" element={<AwanaTestgeniusPluginPage />} />
    <Route path="/awana-craftest" element={<AwanaCraftestPluginPage />} />
    <Route path="/awana-checkmate" element={<AwanaCheckmatePluginPage />} />
    <Route path="/awana-loadtest" element={<AwanaLoadTestPluginPage />} />

    <Route
      path="/multi-region"
      element={
        <RequirePermission
          permission={awsAccountsCloudopsPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={awsAccountsCloudopsPermission}
            />
          }
        >
          <MultiRegionPage />
        </RequirePermission>
      }
    />
    <Route path="/update-eks" element={<UpdateEKSPage />} />
    <Route
      path="/feedback"
      element={
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={
            <InsufficientPermissionsPage
              permission={adminPanelAccessPermission}
            />
          }
        >
          <GlobalFeedbackPage />
        </RequirePermission>
      }
    />
    <Route
      path="/nx-infra"
      element={
        <PresentationPluginPage
          contentTitle="Plugins developed by CloudOps team"
          headerSubtitle="Plugins for Infra"
          headerTitle="Nx CloudOps"
          supportButtonText="Support"
          minHeight="120px"
          supportItems={[]}
          cards={[
            {
              Desciption: <MultiRegionCard />,
              HeaderTitle: 'Multi Region',
              HeaderSubtitle: 'Switch infra between regions',
              to: '/multi-region',
              category: PluginCategory.Infrastructure,
            },
            {
              Desciption: <RestartPodsCard />,
              HeaderTitle: 'Restart Pods',
              HeaderSubtitle: 'Restart pods in a Kubernetes cluster',
              to: '/pod-annotations',
              category: PluginCategory.Automation,
            },
          ]}
        />
      }
    />
    <Route
      path="/awana"
      element={
        <PresentationPluginPage
          contentTitle="Catalog of available tools"
          headerTitle="Awana Tools"
          headerSubtitle="Quality management tools"
          supportButtonText="Support Nx-Awana"
          minHeight="130px"
          supportItems={[
            {
              links: [
                {
                  title: 'Plugins Documentation',
                  url: 'https://naranjafintech.atlassian.net/wiki/x/yABNE3s',
                },
                {
                  title: 'Slack Channel',
                  url: 'https://naranja-edge.enterprise.slack.com/archives/C06EKKESDD0',
                },
              ],
              title: 'Support',
            },
          ]}
          cards={[
            /** The Awana team requested to hide this presentation card but keep the route */
            // {
            //   Desciption: <AwanaCheckmateCardPresentation />,
            //   HeaderTitle: 'Checkmate - Mobile Automated Test',
            //   HeaderSubtitle:
            //     'Execute mobile UI automated test in a real device.',
            //   to: '/awana-checkmate',
            //   category: PluginCategory.Deployment,
            // },
            {
              Desciption: <AwanaStoryTellerCardPresentation />,
              HeaderTitle: 'Storyteller - Users History',
              HeaderSubtitle: 'Create users history with AI',
              to: '/awana-story-teller',
              category: PluginCategory.ArtificialIntelligence,
            },
            {
              Desciption: <AwanaCraftestCardPresentation />,
              HeaderTitle: 'Craftest - Test cases',
              HeaderSubtitle: 'Create test cases with AI',
              to: '/awana-craftest',
              category: PluginCategory.Infrastructure,
            },
            {
              Desciption: <AwanaTestgeniusCardPresentation />,
              HeaderTitle: 'Testgenius - Automated test cases',
              HeaderSubtitle: 'Create awana-backend-basics test cases with AI',
              to: '/awana-testgenius',
              category: PluginCategory.ApiManagement,
            },
            // {
            //   Desciption: <AwanaLoadTestCardPresentation />,
            //   HeaderTitle: 'Load Test',
            //   HeaderSubtitle: 'Generate reusable load tests',
            //   to: '/awana-loadtest',
            //   category: PluginCategory.Deployment,
            // },
          ]}
        />
      }
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
      <OpcFeedbackComponent />
    </AppRouter>
  </>,
);
