import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line
import { BasicPermission } from '@backstage/plugin-permission-common';
import accessDenied from '../../../assets/AccessDenied.png';

interface InsufficientPermissionsPageProps {
  permission: BasicPermission;
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '70px 0px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    textAlign: 'center',
    maxWidth: '600px',
    marginBottom: theme.spacing(1),
  },
  image: {
    width: '100%',
    maxWidth: '650px',
    height: 'auto'
  },
}));

export const InsufficientPermissionsPage = ({ permission }: InsufficientPermissionsPageProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant="h1" className={classes.title}>
       Access Denied 
      </Typography> 
      <Typography variant="h5" className={classes.message}>
        Oooopss! It looks like you don't have enough permissions to access this page.
      </Typography>
      <img
        className={classes.image}
        src={accessDenied}
        alt="Access Denied"
      />
      <Typography variant="body1" className={classes.message}>
        No worries! If you think you should have access, just reach out us on the Slack channel #khatu and request the permission: {permission?.name}.
      </Typography>
    </Grid>
  );
};