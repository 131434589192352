import { Typography } from '@material-ui/core';
import React from 'react';

export const RestartPodsCard = () => {
  return (
    <Typography variant="body1" align="justify">
      This plugin allows restarting pods in a Kubernetes cluster. It ensures
      high availability and disaster recovery by automatically restarting pods
      in case of failure. Easily configurable via the settings page.
    </Typography>
  );
};
